/* Reset default margin and padding for HTML and body */
html {
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }

  
  
  /* Set a background color and font family for the entire page */
  body {
    background-color: #f0f0f0;
    font-family: Arial;
}
  

.interior-body {
display: flex;
flex-direction: column;
padding:20px 10%;
/* align-items: center; */
}

  /* Style the h1 element */
  h1 {
    font-size: 24px;
    color: #333;
    margin: 20px 0;
  }
  
  /* Style the h2 element */
  h2 {
    font-size: 20px;
    color: #444;
    margin: 15px 0;
  }
  
  /* Style paragraphs */
  p {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
    margin: 10px 0;
  }
  
  /* Style the navigation bar */
  .navbar {
    background-color: #333;
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    padding: 20px;
    height: 100px;
  }
  
  /* Style the footer */
  .footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
  }
  
.btn {
background-color: #666;
color: white
}

.stims_row {
  display: flex;
  flex-direction: row;
}

.large-open-input {
  width:40%;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-bottom: 1px solid black;
}

.tab-buttons {
  display: flex;
  flex-direction: row;
  width: 100%; /* Make the tab buttons stretch across the view */
}

tabButton {
  flex: 1; /* Distribute available space evenly among buttons */
  border: none;
  border-left: 1px solid rgb(206, 206, 206);
  background-color: white;
  cursor: pointer;
  padding: 10px;
  outline: none;
}

tabButton.active {
  background-color: #777777;
}

chartButton {
  display: flex;
  flex:1;
  justify-content: center;
  align-items: center;
  border: none; /* Remove button border */
  cursor: pointer;  
}

chartButton:hover {
  color: #a2a2a2;
  text-decoration: underline;
}

.search-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333; /* Change the color to match your design */
}

.search-button i {
  margin-right: 5px; /* Adjust the margin to control the space between the icon and text */
}